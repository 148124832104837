export default [
  {
    path: "/sports/sports-league",
    name: "sports-league-list",
    component: () => import("@/views/sports/SportsLeagueList.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Sports Management",
        },
        {
          text: "Sports League List",
          active: true,
        },
      ],
      action: "view",
      resource: "sportleague",
    },
  },
  {
    path: "/sports/type",
    name: "sports-type",
    component: () => import("@/views/sports/SportsType.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Sports Management",
        },
        {
          text: "Sports Type",
          active: true,
        },
      ],
      action: "view",
      resource: "sporttype",
    },
  },
  {
    path: "/sports/season",
    name: "sports-season",
    component: () => import("@/views/sports/SportsSeason.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Sports Management",
        },
        {
          text: "Sports Season",
          active: true,
        },
      ],
      action: "view",
      resource: "sportseason",
    },
  },
  {
    path: "/sports/team",
    name: "sports-team",
    component: () => import("@/views/sports/SportsTeam.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Sports Management",
        },
        {
          text: "Sports Team",
          active: true,
        },
      ],
      action: "view",
      resource: "sportteam",
    },
  },
  {
    path: "/sports/hot-match",
    name: "hot-sport-match",
    component: () => import("@/views/sports/HotSportMatch.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Sports Management",
        },
        {
          text: "Sport Hot Match",
          active: true,
        },
      ],
      action: "view",
      resource: "sportmatch",
    },
  },
  {
    path: "/sports/bv-hot-match",
    name: "bv-hot-sport-match",
    component: () => import("@/views/sports/BVHotSportMatch.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Sports Management",
        },
        {
          text: "BV Sport Hot Match",
          active: true,
        },
      ],
      action: "view",
      resource: "bvsporthotmatch",
    },
  },
];
