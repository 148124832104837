export default [
	{
		path: '/reason/adjustment-reason',
		name: 'reason-adjustment',
		component: () => import('@/views/reason/adjustment-reason/AdjustmentReasonList.vue'),
		meta: {
			breadcrumb: [
				{
					text: 'Category Management',
				},
				{
					text: 'Adjustment Reason',
					active: true,
				},
			],
			action: 'read',
			resource: 'Auth',
			// Chưa có role trên server
		}
	},
	{
		path: '/reason/template-sms',
		name: 'template-sms',
		component: () => import('@/views/reason/template-sms/TemplateSmsManagement'),
		meta: {
			breadcrumb: [
				{
					text: 'Category Management',
				},
				{
					text: 'Template Sms Management',
					active: true,
				},
			],
			action: 'view',
			resource: 'smstemplate',
			// Chưa có role trên server
		}
	},
	{
		path: '/reason/title-sms',
		name: 'title-sms',
		component: () => import('@/views/reason/title-sms/TitleSmsManagement'),
		meta: {
			breadcrumb: [
				{
					text: 'Category Management',
				},
				{
					text: 'Title Sms Management',
					active: true,
				},
			],
			action: 'view',
			resource: 'smstitle',
			// Chưa có role trên server
		}
	},
]
