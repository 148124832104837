import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import apps from "./routes/apps";
import payments from "./routes/payments";
import campaign from "./routes/campaign";
import localSetting from "./routes/local-setting";
import dashboard from "./routes/dashboard";
import uiElements from "./routes/ui-elements/index";
import pages from "./routes/pages";
import chartsMaps from "./routes/charts-maps";
import formsTable from "./routes/forms-tables";
import others from "./routes/others";
import members from "./routes/members";
import bonus from "./routes/bonus";
import banks from "./routes/banks";
import reason from "./routes/reason";
import agent from "./routes/agent";
import agency from "./routes/agency";
import marketing from "./routes/marketing";
import frontend from "./routes/frontend";
import sports from "./routes/sports";
import account from "./routes/account";
import log from "./routes/log";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: "/", redirect: { name: "dashboard-analytics" } },
    ...apps,
    ...members,
    ...banks,
    ...payments,
    ...reason,
    ...bonus,
    ...agent,
    ...agency,
    ...marketing,
    ...campaign,
    ...localSetting,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...frontend,
    ...sports,
    ...account,
    ...log,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  // console.log(canNavigate(to))

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) {
      if (to.name !== "auth-login") next({ name: "auth-login" });
    } else if (to.name !== "misc-not-authorized")
      next({ name: "misc-not-authorized" });

    // If logged in => not authorized
    // else next({ name: 'misc-not-authorized' })
    // return next()
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
