import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
	// You can add your headers here
	// ================================
	baseURL: 'http://139.99.62.150/',
	timeout: 1000 * 10,
	// headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
