export default [
  {
    path: "/account/deposit-withdrawal",
    name: "account-deposit-withdrawal",
    component: () => import("@/views/account/AccountDepositWithdrawal.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Account Management",
        },
        {
          text: "Deposit/Withdrawal",
          active: true,
        },
      ],
      action: "read",
      resource: "accountingdepositwithdrawal",
    },
  },
  {
    path: "/account/win-loss",
    name: "account-win-loss",
    component: () => import("@/views/account/AccountWinLoss.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Account Management",
        },
        {
          text: "Win/Loss",
          active: true,
        },
      ],
      action: "read",
      resource: "accountingwinloss",
    },
  },
];
