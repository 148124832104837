export default [
	{
		path: '/campaign/marketing/bonus-category',
		name: 'campaign-marketing-bonus-category',
		component: () => import('@/views/campaign/marketing/BonusCategory.vue'),
		meta: {
			pageTitle: 'Bonus Category'
		}
	},
	{
		path: '/campaign/marketing/bonus-code',
		name: 'campaign-marketing-bonus-code',
		component: () => import('@/views/campaign/marketing/BonusCode.vue'),
		meta: {
			pageTitle: 'Bonus Code'
		}
	},
	{
		path: '/campaign/marketing/user-bonus-code',
		name: 'campaign-marketing-user-bonus-code',
		component: () => import('@/views/campaign/marketing/UserBonusCode.vue'),
		meta: {
			pageTitle: 'User Bonus Code'
		}
	},
	{
		path: '/campaign/stake-amount/rebate-ratio',
		name: 'campaign-stake-amount-rebate-ratio',
		component: () => import('@/views/campaign/stake-amount/RebateRatio.vue'),
		meta: {
			pageTitle: 'Rebate Ratio'
		}
	},
	{
		path: '/campaign/stake-amount/report',
		name: 'campaign-stake-amount-report',
		component: () => import('@/views/campaign/stake-amount/Report.vue'),
		meta: {
			pageTitle: 'Eligible Stake Amount Report',
			resource: 'esa',
			action: 'view',
		}
	},
	{
		path: '/campaign/risk-management/top-winners',
		name: 'campaign-risk-management-top-winners',
		component: () => import('@/views/campaign/risk-management/TopWinners.vue'),
		meta: {
			pageTitle: 'Top Winners',
			action: 'read',
			resource: 'topwinners'
		}
	},
	{
		path: '/campaign/risk-management/top-balance',
		name: 'campaign-risk-management-top-balance',
		component: () => import('@/views/campaign/risk-management/TopBalance.vue'),
		meta: {
			pageTitle: 'Top Balance'
		}
	},
	{
		path: '/campaign/risk-management/top-deposit',
		name: 'campaign-risk-management-top-deposit',
		component: () => import('@/views/campaign/risk-management/TopDeposit.vue'),
		meta: {
			pageTitle: 'Top Deposit',
			action: 'view',
			resource: 'topdeposit',
		}
	},
	{
		path: '/campaign/risk-management/top-transfer',
		name: 'campaign-risk-management-top-transfer',
		component: () => import('@/views/campaign/risk-management/TopTransfer.vue'),
		meta: {
			pageTitle: 'Top Transfer',
			action: 'view',
			resource: 'toptransfer',
		}
	},
	{
		path: '/campaign/risk-management/top-withdarawal',
		name: 'campaign-risk-management-top-withdrawal',
		component: () => import('@/views/campaign/risk-management/TopWithdrawal.vue'),
		meta: {
			pageTitle: 'Top Withdrawal',
			action: 'view',
			resource: 'topwithdrawal',
		}
	},
]
