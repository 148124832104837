export default [
	{
		path: "/payments/type",
		name: "payments-type",
		component: () => import("@/views/payments/type/PaymentTypeList.vue"),
		meta: {
			breadcrumb: [
				{
					text: "Payment",
				},
				{
					text: "Payment Type",
					active: true,
				},
			],
			action: "read",
			resource: "paymenttypeindex",
		},
	},
	{
		path: "/payments/auto-withdrawal-limit",
		name: "payments-auto-withdrawal-limit",
		component: () =>
			import(
				"@/views/payments/auto-withdrawal-limit/AutoWithdrawalLimitList.vue"
			),
		meta: {
			breadcrumb: [
				{
					text: "Payment",
				},
				{
					text: "Auto Withdrawal Limit",
					active: true,
				},
			],
			action: "view",
			resource: "autowithdrawallimit",
		},
	},
	{
		path: "/payments/deposit",
		name: "payments-deposit",
		component: () => import("@/views/payments/deposit/Deposit.vue"),
		meta: {
			breadcrumb: [
				{
					text: "Payment",
				},
				{
					text: "Deposit Transactions",
					active: true,
				},
			],
			action: "view",
			resource: "deposit",
		},
	},
	{
		path: "/payments/withdrawal",
		name: "payments-withdrawal",
		component: () => import("@/views/payments/withdrawal/Withdrawal.vue"),
		meta: {
			breadcrumb: [
				{
					text: "Payment",
				},
				{
					text: "Withdrawal Transactions",
					active: true,
				},
			],
			action: "view",
			resource: "withdrawal",
		},
	},
	{
		path: "/payments/transfer",
		name: "payments-transfer",
		component: () => import("@/views/payments/transfer/Transfer.vue"),
		meta: {
			breadcrumb: [
				{
					text: "Payment",
				},
				{
					text: "Transfer Transactions",
					active: true,
				},
			],
			action: "view",
			resource: "transfer",
		},
	},
	{
		path: "/payments/adjustment",
		name: "payments-adjustment",
		component: () => import("@/views/payments/adjustment/Adjustment.vue"),
		meta: {
			breadcrumb: [
				{
					text: "Payment",
				},
				{
					text: "Adjustment Transactions",
					active: true,
				},
			],
			action: "view",
			resource: "adjustbalance",
		},
	},
	{
		path: "/payments/batch-adjustment",
		name: "payments-batch-adjustment",
		component: () =>
			import("@/views/payments/batch-adjustment/BatchAdjustmentList.vue"),
		meta: {
			breadcrumb: [
				{
					text: "Payment",
				},
				{
					text: "Batch Adjustment",
					active: true,
				},
			],
			action: "view",
			resource: "adjustbalance",
		},
	},
	{
		path: "/payments/deposit-type",
		name: "payments-deposit-type",
		component: () => import("@/views/payments/deposit-type/DepositType.vue"),
		meta: {
			breadcrumb: [
				{
					text: "Payment",
				},
				{
					text: "Deposit Type",
					active: true,
				},
			],
			action: "view",
			resource: "deposittype",
		},
	},
	{
		path: "/payments/card-transaction",
		name: "payments-card-transaction",
		component: () => import("@/views/payments/card-transaction/CardTransaction.vue"),
		meta: {
			breadcrumb: [
				{
					text: "Payment",
				},
				{
					text: "Card Transaction",
					active: true,
				},
			],
			action: "view",
			resource: "cardtransaction",
		},
	},
]