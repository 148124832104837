export default [
	/* MEMBER */
	{
		path: '/frontend/banners/list',
		name: 'frontend-banners-list',
		component: () => import('@/views/frontend/banners/BannersList.vue'),
		meta: {
			breadcrumb: [
				{
					text: 'Frontend',
				},
				{
					text: 'Banners List',
					active: true,
				},
			],
			action: 'view',
			resource: 'banner',
		}
	},
	{
		path: '/frontend/notifications/list',
		name: 'frontend-notifications-list',
		component: () => import('@/views/frontend/notifications/NotificationsList.vue'),
		meta: {
			breadcrumb: [
				{
					text: 'Frontend',
				},
				{
					text: 'Notifications List',
					active: true,
				},
			],
			action: 'view',
			resource: 'notification',
		}
	},
	{
		path: '/frontend/post/category',
		name: 'frontend-post-category',
		component: () => import('@/views/frontend/post/post-category/PostCategoryList.vue'),
		meta: {
			breadcrumb: [
				{
					text: 'Frontend',
				},
				{
					text: 'Post category List',
					active: true,
				},
			],
			action: 'view',
			resource: 'postcategory',
		}
	},
	{
		path: '/frontend/post/index',
		name: 'frontend-post',
		component: () => import('@/views/frontend/post/PostList.vue'),
		meta: {
			breadcrumb: [
				{
					text: 'Frontend',
				},
				{
					text: 'Post List',
					active: true,
				},
			],
			action: 'view',
			resource: 'post',
		}
	},
	{
		path: '/frontend/information',
		name: 'frontend-information',
		component: () => import('@/views/frontend/information/InformationList.vue'),
		meta: {
			breadcrumb: [
				{
					text: 'Frontend',
				},
				{
					text: 'Information',
					active: true,
				},
			],
			action: 'view',
			resource: 'info',
		}
	},
	{
		path: '/frontend/contact/category',
		name: 'frontend-contact-category',
		component: () => import('@/views/frontend/contact-category/PostContactCategory.vue'),
		meta: {
			breadcrumb: [
				{
					text: 'Frontend',
				},
				{
					text: 'Contact Category',
					active: true,
				},
			],
			action: 'view',
			resource: 'contactcategory',
		}
	},
	{
		path: '/frontend/contact',
		name: 'frontend-contact',
		component: () => import('@/views/frontend/contact/PostContact.vue'),
		meta: {
			breadcrumb: [
				{
					text: 'Frontend',
				},
				{
					text: 'Contact',
					active: true,
				},
			],
			action: 'read',
			resource: 'contactmanager',
			// role bị trùng key aciton
		}
	},
]