export default [
  {
    path: "/logs/mail-log",
    name: "logs-mail-log",
    component: () => import("@/views/logs/MailLog.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Log Management",
        },
        {
          text: " Mail Log",
          active: true,
        },
      ],
      action: "view",
      resource: "maillog",
    },
  },
  {
    path: "/logs/voice-otp-log",
    name: "logs-voice-otp-log",
    component: () => import("@/views/logs/VoiceOptLog.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Log Management",
        },
        {
          text: "Voice Opt Log",
          active: true,
        },
      ],
      action: "view",
      resource: "voiceotplog",
    },
  },
  // {
  //   path: "/sports/type",
  //   name: "sports-type",
  //   component: () => import("@/views/sports/SportsType.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Sports Management",
  //       },
  //       {
  //         text: "Sports Type",
  //         active: true,
  //       },
  //     ],
  //     action: "view",
  //     resource: "sporttype",
  //   },
  // },
  // {
  //   path: "/sports/season",
  //   name: "sports-season",
  //   component: () => import("@/views/sports/SportsSeason.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Sports Management",
  //       },
  //       {
  //         text: "Sports Season",
  //         active: true,
  //       },
  //     ],
  //     action: "view",
  //     resource: "sportseason",
  //   },
  // },
  // {
  //   path: "/sports/team",
  //   name: "sports-team",
  //   component: () => import("@/views/sports/SportsTeam.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Sports Management",
  //       },
  //       {
  //         text: "Sports Team",
  //         active: true,
  //       },
  //     ],
  //     action: "view",
  //     resource: "sportteam",
  //   },
  // },
];
