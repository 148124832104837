export default [
  {
    path: "/payments/bank-account",
    name: "payments-bank-account",
    component: () => import("@/views/payments/bank-account/BankAccount.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bank Management",
        },
        {
          text: "User Bank Account",
          active: true,
        },
      ],
      action: "view",
      resource: "userbankaccount",
    },
  },
  {
    path: "/payments/local-bank-account",
    name: "payments-local-bank-account",
    component: () =>
      import("@/views/payments/local-bank-account/LocalBankAccount.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bank Management",
        },
        {
          text: "Local Bank Account",
          active: true,
        },
      ],
      action: "view",
      resource: "localbankaccount",
    },
  },
  {
    path: "/payments/bank-manage",
    name: "bank-manager",
    component: () => import("@/views/payments/bank-manager/BankManager.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Bank Management",
        },
        {
          text: "Bank Manager",
          active: true,
        },
      ],
      action: "view",
      resource: "bank",
    },
  },
];
