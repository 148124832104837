export default [
  /* MEMBER */
  {
    path: "/apps/members/list",
    name: "apps-members-list",
    component: () => import("@/views/apps/member/members-list/UsersList.vue"),
    meta: {
      resource: "staff",
      action: "view",
      breadcrumb: [
        {
          text: "Staff Management",
        },
        {
          text: "Users List",
          active: true,
        },
      ],
    },
  },
  /* {
		path: '/apps/users/view/:id',
		name: 'apps-users-view',
		component: () => import('@/views/apps/user/users-view/UsersView.vue'),
	},
	{
		path: '/apps/users/edit/:id',
		name: 'apps-users-edit',
		component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
	}, */

  {
    path: "/apps/members/department",
    name: "apps-members-department",
    component: () =>
      import("@/views/apps/member/department/DepartmentsList.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Staff Management",
        },
        {
          text: "Departments List",
          active: true,
        },
      ],
      resource: "department",
      action: "view",
    },
  },
  {
    path: "/apps/members/role",
    name: "apps-members-role",
    component: () => import("@/views/apps/member/role/RolesList.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Staff Management",
        },
        {
          text: "Roles List",
          active: true,
        },
      ],
      resource: "role",
      action: "view",
    },
  },
  // {
  //   path: "/apps/members/audit-login",
  //   name: "apps-members-audit-login",
  //   component: () => import("@/views/apps/member/audit-login/AuditLogin.vue"),
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: "Staff Management",
  //       },
  //       {
  //         text: "Audit Login",
  //         active: true,
  //       },
  //     ],
  //     resource: "userauditlogin",
  //     action: "read",
  //   },
  // },
];
