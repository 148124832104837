export default [
	{
		path: '/dashboard/analytics',
		name: 'dashboard-analytics',
		component: () => import('@/views/dashboard/analytics/Analytics.vue'),
		meta: {
			action: 'read',
			resource: 'Auth',
		},
	},
]
