export default [
  {
    path: "/vendor/list",
    name: "agent-list",
    component: () => import("@/views/agent/Agent.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Agent",
        },
        {
          text: "Agent list",
          active: true,
        },
      ],
      action: "view",
      resource: "agent",
    },
  },
  {
    path: "/vendor/agent-wallet",
    name: "agent-wallet",
    component: () => import("@/views/agent/AgentWallet.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Vendor",
        },
        {
          text: "Game wallet",
          active: true,
        },
      ],
      action: "read",
      resource: "gamewalletmanagement",
    },
  },
  {
    path: "/vendor/product",
    name: "agent-product",
    component: () => import("@/views/agent/Product.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Vendor",
        },
        {
          text: "Product",
          active: true,
        },
      ],
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/vendor/game",
    name: "agent-game",
    component: () => import("@/views/agent/Game.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Vendor",
        },
        {
          text: "Game",
          active: true,
        },
      ],
      action: "read",
      resource: "Auth",
    },
  },
  {
    path: "/vendor/game-type",
    name: "agent-game-type",
    component: () => import("@/views/agent/GameType.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Vendor",
        },
        {
          text: "Game type",
          active: true,
        },
      ],
      action: "read",
      resource: "gamemanagement",
    },
  },
  {
    path: "/vendor/game-category",
    name: "agent-game-category",
    component: () => import("@/views/agent/GameCategory.vue"),
    meta: {
      breadcrumb: [
        {
          text: "Vendor",
        },
        {
          text: "Game category",
          active: true,
        },
      ],
      action: "read",
      resource: "gamecategorymanagement",
    },
  },
  {
    path: "/vendor/win-loss-by-user",
    name: "agent-win-loss-by-user",
    component: () => import("@/views/agent/WinLossByUser"),
    meta: {
      breadcrumb: [
        {
          text: "Vendor",
        },
        {
          text: "Win Loss By User",
          active: true,
        },
      ],
      action: "read",
      resource: "agentwinloss",
    },
  },
  {
    path: "/vendor/win-loss-by-product",
    name: "agent-win-loss-by-product",
    component: () => import("@/views/agent/WinLossByProduct"),
    meta: {
      breadcrumb: [
        {
          text: "Vendor",
        },
        {
          text: "Win Loss By Product",
          active: true,
        },
      ],
      action: "read",
      resource: "agentwinlossbyproduct",
    },
  },
  {
    path: "/vendor/win-loss-general",
    name: "agent-win-loss-general",
    component: () => import("@/views/agent/WinLossGeneral"),
    meta: {
      breadcrumb: [
        {
          text: "Vendor",
        },
        {
          text: "Win Loss General",
          active: true,
        },
      ],
      action: "read",
      resource: "agentwinlossgeneral",
    },
  },
];
