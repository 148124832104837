export default [
    {
        path: "/agency",
        name: "agency",
        component: () => import("@/views/marketing/Agency.vue"),
        meta: {
            breadcrumb: [
                {
                    text: "Agency Management",
                },
                {
                    text: "Agency List",
                    active: true,
                },
            ],
            action: "read",
            resource: "agencylistmanagement",
        },
    },
    {
        path: "/agency/detail/:id",
        name: "agency-detail",
        component: () => import("@/views/marketing/agency-detail/AgencyDetail.vue"),
        meta: {
            pageTitle: "Detail Agency",
            breadcrumb: [
                {
                    text: "Agency Management",
                    to: { name: "agency" },
                },
                {
                    text: "Agency View",
                    active: true,
                },
            ],
            action: "read",
            resource: "agencydetailmanager",
        },
    },
    {
        path: "/agency/commission",
        name: "agency-commission",
        component: () => import("@/views/marketing/AgencyCommission.vue"),
        meta: {
            pageTitle: "Agency Commission",
            breadcrumb: [
                {
                    text: "Agency Management",
                },
                {
                    text: "Agency Commissions",
                    active: true,
                },
            ],
            action: "read",
            resource: "agencycommissionsmanagement",
        },
    },
    {
        path: "/agency/revenue-setting",
        name: "agency-revenue-setting",
        component: () => import("@/views/marketing/AgencyRevenueSetting.vue"),
        meta: {
            pageTitle: "Revenue Setting",
            breadcrumb: [
                {
                    text: "Agency Management",
                },
                {
                    text: "Revenue Setting",
                    active: true,
                },
            ],
            action: "read",
            resource: "agencyrevenuesetting",
        },
    },
    {
        path: "/agency/login-log",
        name: "agency-login-log",
        component: () => import("@/views/marketing/AgencyLoginLog.vue"),
        meta: {
            pageTitle: "Login Log",
            breadcrumb: [
                {
                    text: "Agency Management",
                },
                {
                    text: "Login Log",
                    active: true,
                },
            ],
            action: "view",
            resource: "agencyloginlog",
        },
    },
];
