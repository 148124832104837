export default [
	{
		path: '/local-setting/local/province',
		name: 'local-setting-local-province',
		component: () => import('@/views/local-setting/local/Province.vue'),
		meta: {
			pageTitle: 'Province'
		}
	},
    {
		path: '/local-setting/local/bank',
		name: 'local-setting-local-bank',
		component: () => import('@/views/local-setting/local/Bank.vue'),
		meta: {
			pageTitle: 'Bank'
		}
	},
    {
		path: '/local-setting/local/role',
		name: 'local-setting-local-role',
		component: () => import('@/views/local-setting/local/Role.vue'),
		meta: {
			pageTitle: 'Role'
		}
	},
    {
		path: '/local-setting/setting',
		name: 'local-setting-setting',
		component: () => import('@/views/local-setting/setting/Setting.vue'),
		meta: {
			pageTitle: 'Setting'
		}
	},
    {
		path: '/local-setting/notification',
		name: 'local-setting-notification',
		component: () => import('@/views/local-setting/notification/Notification.vue'),
		meta: {
			pageTitle: 'Notification'
		}
	},
	{
		path: '/local-setting/setting/param',
		name: 'local-setting-setting-param',
		component: () => import('@/views/local-setting/setting/param/Param.vue'),
		meta: {
            breadcrumb: [
                {
                    text: "Setting",
                },
                {
                    text: "Param Management",
                    active: true,
                },
            ],
            action: "view",
            resource: "param",
        },
	},
	{
		path: '/local-setting/setting/balance-type',
		name: 'local-setting-setting-balance-type',
		component: () => import('@/views/local-setting/setting/balance-type/BalanceType.vue'),
		meta: {
            breadcrumb: [
                {
                    text: "Setting",
                },
                {
                    text: "Balance Type Management",
                    active: true,
                },
            ],
            action: "view",
            resource: "balancetype",
        },
	},
	{
		path: '/local-setting/setting/third-partner',
		name: 'local-setting-setting-third-partner',
		component: () => import('@/views/local-setting/setting/third-partner/ThirdPartner.vue'),
		meta: {
            breadcrumb: [
                {
                    text: "Setting",
                },
                {
                    text: "Third Partner Management",
                    active: true,
                },
            ],
            action: "view",
            resource: "thirdpartner",
        },
	},
	{
		path: '/local-setting/setting/level-rank',
		name: 'local-setting-setting-level-rank',
		component: () => import('@/views/local-setting/setting/level-rank/LevelRank.vue'),
		meta: {
            breadcrumb: [
                {
                    text: "Setting",
                },
                {
                    text: "Level Rank Management",
                    active: true,
                },
            ],
            action: "view",
            resource: "levelrank",
        },
	},
]
